import { Center, Stack, VStack } from "@chakra-ui/react";
import React from "react";
import NavBar from "../components/nav/NavBar";
import { Heading, Text } from "@equidefi/ui";

function GenericInfoArea({ heading, title, message, offering }) {
  return (
    <Stack
      direction={{ base: "column", lg: "row" }}
      as="main"
      bgColor="background"
      h="100vh"
    >
      <NavBar offering={offering}>
        <Center w="full" h={{ base: "full", lg: "100vh" }}>
          <VStack
            align="start"
            justify="center"
            maxWidth="560px"
            w="full"
            p={4}
          >
            <Heading
              fontWeight="semibold"
              fontSize="4em"
              mb={{ base: "4", lg: "10" }}
            >
              {heading}
            </Heading>
            {title && (
              <Heading textStyle="h1" fontWeight="bold">
                {title}
              </Heading>
            )}
            {message && <Text mb="10">{message}</Text>}
          </VStack>
        </Center>
      </NavBar>
    </Stack>
  );
}

export default GenericInfoArea;

import { useToast } from "@chakra-ui/react";
import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { WorkflowContext } from "../pages/workflow/context";
import {
  useMFACode,
  useWorkflowRegister as useRegister,
} from "@equidefi/portals/hooks/useAuthentication";

export const COMPLETED_STATUSES = [
  "DILIGENCE",
  "COUNTERSIGN",
  "REFUND",
  "CLOSING",
];

export const useWorkflowRegister = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [cookies] = useCookies(["bcode"]);
  const { refetch: refetchUser } = useCurrentUser();

  const { investment, analytics, offering } = useContext(WorkflowContext);

  const register = useRegister(offering.id);
  const confirmMFA = useMFACode();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const onSubmitRegistration = async (data) => {
    setIsLoading(true);
    const time = new Date();

    try {
      const response = await register.mutateAsync({
        email: data.email,
        password: data.password,
        bcode: `${cookies.bcode}`,
        accept_tos: time,
        accept_privacy: time,
        type: "investor",
      });

      if (response.status === 201) {
        refetchUser();
        dispatch({ type: "email", payload: data.email });
        dispatch({ type: "user", payload: response.data.user });

        toast({
          status: "success",
          description: "Your investment was created, let's get started!",
        });

        navigate(
          `/offerings/${offering.slug}/${response.data.investment.id}/profile`,
          { state: data }
        );
      } else if (response.status === 200) {
        dispatch({ type: "email", payload: data.email });
        navigate(`/offerings/${offering.slug}/mfa`, { state: data });
        return "register";
      }
    } catch (error) {
      let errorMessage = "There was an error trying to register your account.";
      if (error.response.status === 401) {
        errorMessage = "Login failed, try again or reset your password";
        setShowForgotPassword(true);
      }
      if (error.response.status === 403) {
        errorMessage = "You are not allowed to access this portal";
      }
      toast({
        status: "error",
        description: errorMessage,
      });
    }

    setIsLoading(false);
  };

  const onSubmitMFA = async (email, code) => {
    setIsLoading(true);
    try {
      await confirmMFA.mutateAsync({
        email: email,
        mfa_code: code,
      });

      await onMFAComplete();
    } catch (e) {
      console.error(e);
      toast({
        status: "error",
        description: "Incorrect code, please try again",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onMFAComplete = async () => {
    try {
      analytics.track("registered");
      dispatch({ type: "email", payload: undefined });
      refetchUser();
      navigate(`/offerings/${offering.slug}/choose`);
    } catch (error) {
      toast({
        status: "error",
        description:
          "There was an error trying to create your initial investment.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const isCompleted = (investment) => {
    if (!investment?.next) {
      return false;
    }

    return COMPLETED_STATUSES.includes(investment.next);
  };

  useEffect(() => {
    if (investment?.next) {
      // Redirect to dashboard when the investment is completed
      if (isCompleted(investment)) {
        navigate("/vault/dashboard");
      } else {
        // Determine initial uri to load
        navigate(
          `/offerings/${offering.slug}/${
            investment.id
          }/${investment.next.toLowerCase()}`
        );
      }
    }
  }, [investment, navigate, offering]);

  return {
    isLoading,
    showForgotPassword,
    onSubmitRegistration,
    onSubmitMFA,
  };
};

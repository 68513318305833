import { Heading } from "@chakra-ui/react";
import { useFormik } from "formik";
import React from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import { Input, Password, Text } from "@equidefi/ui";
import {
  PASSWORD_REGEX,
  PASSWORD_MESSAGE,
} from "@equidefi/shared/constants/password";

import { useWorkflowRegister } from "../../../hooks/useWorkflowRegister";
import { WorkflowContainer } from "../WorkflowContainer";
import { isRegD506COffering } from "../../../helpers";
import WorkflowForm from "../WorkflowForm";
import RegDOfferingAlert from "./RegDOfferingAlert";
import "./auth.css";

const userSchema = Yup.object().shape({
  email: Yup.string()
    .nullable()
    .email("Must be a valid email")
    .required("Please enter your email address"),
  password: Yup.string()
    .required("Please enter a password")
    .matches(PASSWORD_REGEX, PASSWORD_MESSAGE),
});

const initialValues = {
  accepted_tos: null,
  email: "",
  password: "",
};

const Register = ({ offering }) => {
  const workflowRegister = useWorkflowRegister();

  const onSubmit = async (data) => {
    await workflowRegister.onSubmitRegistration(data);
  };

  const { handleSubmit, getFieldProps, errors, isValid, touched } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: userSchema,
    onSubmit,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
  });

  return (
    <WorkflowContainer
      title={`Welcome!`}
      buttonProps={{
        isDisabled: !isValid,
        isLoading: workflowRegister.isLoading,
      }}
      onContinue={handleSubmit}
      buttonLabel="Get Started"
    >
      <Heading as="h5" size="md" mb="5">
        Become an investor in {offering.issuer.name}
      </Heading>
      <Text>
        Start your investment by entering your email and password to create an
        account or sign in.
      </Text>

      {isRegD506COffering(offering.exemption_type) && <RegDOfferingAlert />}

      <WorkflowForm onSubmit={handleSubmit}>
        <Input
          label="Email"
          error={errors.email}
          isInvalid={errors.email && touched.email}
          isRequired
          {...getFieldProps("email")}
        />
        <Password
          label="Password"
          error={errors.password}
          isInvalid={errors.password && touched.password}
          isRequired
          borderRadius={5}
          {...getFieldProps("password")}
          helperText={
            workflowRegister.showForgotPassword ? (
              <Text
                as={Link}
                textDecor="underline"
                _hover={{ color: "blue.700" }}
                color="gray.800"
                fontSize="sm"
                to={`/forgot`}
                state={{
                  redirectUrl: `/offerings/${offering.slug}/registration`,
                }}
              >
                Did you forget your password? Click here to reset.
              </Text>
            ) : null
          }
        />
        <Text textStyle="context">
          By creating an account you are agreeing to the{" "}
          <Text
            textStyle="context"
            as={Link}
            textDecor="underline"
            _hover={{ color: "blue.700" }}
            color="blue.600"
            to="https://equidefi.com/terms-of-service/"
            target="_blank"
          >
            Terms of Service
          </Text>{" "}
          and{" "}
          <Text
            textStyle="context"
            as={Link}
            textDecor="underline"
            _hover={{ color: "blue.700" }}
            color="blue.600"
            to="https://equidefi.com/privacy-policy"
            target="_blank"
          >
            Privacy Policy
          </Text>{" "}
          and consenting to receive email or SMS messages regarding your
          investment and other relevant offers.
        </Text>
      </WorkflowForm>
    </WorkflowContainer>
  );
};

export default Register;

import { Box, Collapse, Flex, HStack, Image } from "@chakra-ui/react";
import { Icon } from "@equidefi/ui/icon";
import EquidefiLogo from "../../images/logo-white.png";
import {
  IssuerInfo,
  NavMenu,
  UserGreeting,
  WorkflowProgress,
} from "./components";
import { useAuth } from "@equidefi/portals/components/AuthProvider";

export const NavMobileView = ({ position, offering, logout, menu }) => {
  const MenuIcon = menu.isOpen ? Icon.X : Icon.Menu;
  const { isLoggedIn } = useAuth();

  return (
    <Flex
      position="sticky"
      direction="column"
      w="full"
      zIndex="2"
      color="white"
      borderBottomRadius="21px"
      p="15px"
      bgColor="primary"
      minH="74px"
      align="center"
      justify="center"
    >
      {position === 1 && !isLoggedIn ? (
        <IssuerInfo offering={offering} />
      ) : (
        <>
          <HStack
            minW="full"
            gap="30px"
            alignItems="center"
            justifyContent="space-between"
            minH="44px"
            px="15px"
          >
            <MenuIcon size="1.5em" cursor="pointer" onClick={menu.onToggle} />
            {offering && position ? (
              <WorkflowProgress offering={offering} position={position} />
            ) : (
              <Image src={EquidefiLogo} alt="EquiDeFi" maxH="44px" />
            )}
            {/* <Icon.HelpCircle
              size="1.5em"
              cursor="pointer"
              className="intercom"
            /> */}
            <Box w="1em" /> {/* placeholder element */}
          </HStack>

          <Box as={Collapse} minW="full" in={menu.isOpen}>
            <UserGreeting />
            <IssuerInfo offering={offering} />
            <NavMenu logout={logout} menu={menu} />
          </Box>
        </>
      )}
    </Flex>
  );
};

import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

import
  {
    Alert,
    AlertDescription,
    AlertIcon,
    Box,
    Flex,
    Heading,
    HStack,
    IconButton,
    useToast,
    VStack,
  } from "@chakra-ui/react";

import AWSClient from "@equidefi/portals/clients/AWSClient";
import { usePresignedUrl } from "@equidefi/portals/hooks/useAccreditation";
import { RESTRICTED_CONTENT_TYPES } from "@equidefi/shared/constants/files";
import { Text } from "@equidefi/ui";
import { Icon } from "@equidefi/ui/icon";

import Spinner from "../../../components/spinner";
import { useIsMobile } from "../../../hooks/useIsMobile";

export const UploadComponent = ({
  title,
  investmentId,
  vaultFields = [],
  handleChange,
  noDocumentWarning,
  mb = 2,
}) => {
  const [files, setFiles] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const getPresignedUrl = usePresignedUrl(investmentId);
  const toast = useToast();
  const isMobile = useIsMobile();

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (files.length + acceptedFiles.length > vaultFields.length) {
        toast({
          status: "error",
          description: `You can upload a maximum of ${vaultFields.length} files.`,
        });
        return;
      }

      const newFiles = [];
      setSpinner(true);
      for (const file of acceptedFiles) {
        const filename = file.name.split(" ").join("_");
        const extension = filename.split(".").pop().toLowerCase();

        if (!Object.keys(RESTRICTED_CONTENT_TYPES).includes(extension)) {
          toast({
            status: "error",
            description: `You cannot upload a ${extension} file type, please upload one of these supported types: ${Object.keys(
              RESTRICTED_CONTENT_TYPES
            ).join(", ")}`,
          });
          continue;
        }

        try {
          const vaultField = vaultFields.at(files.length);
          const response = await getPresignedUrl.mutateAsync({
            vaultField,
            fileExtension: extension,
          });
          const client = new AWSClient();
          await client.s3Upload(
            response.signedUrl,
            file,
            RESTRICTED_CONTENT_TYPES[extension],
            file.size
          );
          handleChange({
            target: {
              name: vaultField,
              value: response.url,
            },
          });
          newFiles.push({
            name: file.name,
            url: response.previewSignedUrl,
          });
          toast({
            status: "success",
            description: "Document uploaded successfully!",
          });
        } catch (e) {
          console.error(e);
          toast({
            status: "error",
            description: "Sorry, the document upload failed.",
          });
        }
      }

      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
      setSpinner(false);
    },
    [files, vaultFields, getPresignedUrl]
  );

  const removeFile = useCallback(
    (index) => {
      const vaultField = vaultFields.at(index);
      handleChange({
        target: {
          name: vaultField,
          value: null,
        },
      });
      setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
      toast({
        status: "success",
        description: `The file was removed!`,
      });
    },
    [files, vaultFields]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <Box borderWidth="1px" borderRadius="lg" p={4} bg="white" mb={mb}>
      <Heading as="h3" size="sm" fontWeight="bold" mb={4}>
        {title}
      </Heading>
      {files.length === 0 && (
        <Alert
          status="warning"
          borderRadius="lg"
          border="1px solid"
          borderColor="equidefi.yellow"
          boxShadow="sm"
          my={4}
        >
          <AlertIcon />
          <AlertDescription>
            <Text fontWeight="bold" m="0" as="h4">
              No Documents Uploaded
            </Text>
            <Text textStyle="body2" m="0">
              {noDocumentWarning}
            </Text>
          </AlertDescription>
        </Alert>
      )}
      <VStack spacing={1} align="stretch">
        {files.map((file, index) => (
          <HStack
            key={index}
            justify="space-between"
            p={1}
            borderBottomWidth={index < files.length - 1 ? "1px" : "0"}
          >
            <Text noOfLines={2} pt="3">
              { file.name }
            </Text>
            <HStack spacing={0}>
              <IconButton
                color="equidefi.blue"
                icon={<Icon.ExternalLink size="1.2em" />}
                aria-label="Link of file"
                size="sm"
                variant="ghost"
                onClick={() => window.open(file.url, "_blank")}
              />
              <IconButton
                color="red.500"
                icon={<Icon.Trash size="1.2em" />}
                aria-label="Remove file"
                size="sm"
                variant="ghost"
                isDisabled={files.length !== index + 1}
                onClick={() => removeFile(index)}
              />
            </HStack>
          </HStack>
        ))}
        {vaultFields.length > files.length && (
          <Box
            {...getRootProps()}
            borderWidth="2px"
            borderStyle="dashed"
            borderRadius="md"
            p={4}
            textAlign="center"
            cursor="pointer"
          >
            <input {...getInputProps()} />
            <Spinner show={spinner} text="Uploading...">
              <Text color="gray.500" pt="3">
                {isMobile ? (
                  <Flex alignItems="center" justifyContent="center">
                    <Text m={2}>Add New File</Text>
                    <Icon.Plus size="1.2em" />
                  </Flex>
                ) : (
                  "Drop Files Here To Upload Them"
                )}
              </Text>
            </Spinner>
          </Box>
        )}
      </VStack>
    </Box>
  );
};

import { Box, useDisclosure } from "@chakra-ui/react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useIsMobile } from "../../hooks/useIsMobile";
import { useRequireUser } from "../../hooks/useRequireUser";
import { NavDesktopView } from "./DesktopView";
import { NavMobileView } from "./MobileView";
import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";

const NavBar = (props) => {
  const { offering, children } = props;
  const logout = useRequireUser({
    onMount: false,
    destination: offering ? 0 : "/", // 0 reloads page
  });
  const isMobile = useIsMobile();
  const menu = useDisclosure();

  const location = useLocation();

  useEffect(() => {
    menu.onClose();
  }, [location.pathname, menu.onClose]);

  return (
    <>
      {isMobile ? (
        <NavMobileView {...props} logout={logout} menu={menu} />
      ) : (
        <NavDesktopView {...props} logout={logout} menu={menu} />
      )}
      <Box ml={{ base: 0, lg: "400px" }} w="full">
        {children}
      </Box>
    </>
  );
};

export default NavBar;

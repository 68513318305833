import { Stack } from "@chakra-ui/react";
import { gsap } from "gsap";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import { identify as identifyFullStory } from "@equidefi/portals/helpers/fullstory";
import { offeringIsCompleted } from "@equidefi/shared";
import { useCookies } from "react-cookie";
import {
  Outlet,
  useLocation,
  useNavigate,
  useNavigation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import NavBar from "../../components/nav/NavBar";
import { useWorkflowStartup } from "../../hooks/useStartup";
import Logo from "../../images/logo.png";
import { QUOTES } from "./constants";
import { WorkflowContext } from "./context";
import styles from "./index.module.css";
import { useAuth } from "@equidefi/portals/components/AuthProvider";
import { trackEvent } from "@intercom/messenger-js-sdk";

const buildOfferingUrl =
  (slug) =>
  (...pieces) =>
    `/offerings/${slug}/${pieces.filter(Boolean).join("/")}`;

const LoadingView = ({ isError }) => {
  const loadingBar = useRef();
  const loader = useRef();
  const selectedIndex = Math.round(Math.random() * 5);
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      gsap.from(loadingBar.current, {
        width: 0,
        duration: 1.85,
        ease: "circ.out",
        delay: 0.5,
      });
    }, loader);

    return () => ctx.revert();
  }, []);

  if (isError) {
    return (
      <div className={styles.loading}>
        <div className={styles.logo}>
          <img src={Logo} alt="EquiDeFi" />
        </div>
        <div className={styles.quoteText}>
          Failed to load the offering, please check your internet connection
        </div>
      </div>
    );
  }

  return (
    <div className={styles.loading} ref={loader}>
      <div className={styles.logo}>
        <img src={Logo} alt="EquiDeFi" />
      </div>
      <div className={styles.loadingBorder}>
        <div className={styles.loadingBar} ref={loadingBar} />
      </div>
      <div className={styles.quoteText}>
        <div className={styles.quote}>{QUOTES[selectedIndex].quote}</div>
        <div className={styles.line} />
        <div className={styles.person}>{QUOTES[selectedIndex].name}</div>
      </div>
    </div>
  );
};

const Workflow = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { state } = useNavigation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [complete, setComplete] = useState(false);
  const [, setCookie] = useCookies(["bcode"]);
  const { isLoggedIn } = useAuth();

  const { slug, investment_id: investmentId } = params;

  const {
    user,
    setUser,
    investment,
    refetchInvestment,
    isLoading,
    offering,
    startUri,
    isError,
    analytics,
  } = useWorkflowStartup(slug, investmentId, complete);
  const [delay, setDelay] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      // Purely for visual purposes to simulate loading the offering
      setDelay(false);
    }, 2250);
  }, []);

  useEffect(() => {
    // MFA Code
    const code = searchParams.get("code");

    // Broker tracking code
    const bcode = searchParams.get("bcode");

    if (bcode) {
      setCookie("bcode", bcode, { path: "/", maxAge: 30758480 }); // set broker code cookie, expires in 1 year
    }

    const fullstoryUrl = identifyFullStory(user);
    if (fullstoryUrl) {
      trackEvent("fullstory-session", {
        "Session Link": { url: fullstoryUrl, value: "Play in FullStory" },
      });
    }

    if (!complete && !code) {
      setSearchParams(searchParams);
    }

    const offeringUrl = buildOfferingUrl(slug);

    // If there is a code, we need to bypass the check and go to the MFA page as expected
    if (code) {
      return () => {};
    }

    if (slug && !isLoggedIn) {
      // If slug is set and no valid, always go to register page
      navigate(offeringUrl("registration"));
      return () => {};
    }

    // If there is an investmentId is in the params, make sure investment is loaded before proceeding
    // If there is no investmentId, we can just execute the below code.
    if (investmentId && isLoading) {
      return () => {};
    }

    if (searchParams.get("payment_intent")) {
      return () => {};
    }

    if (!slug) {
      // Unlikely, but will guard against not having a slug available
      navigate("/");
    } else if (offeringIsCompleted(offering)) {
      // If offering completed, then redirect to investor vault
      navigate("/vault/dashboard");
    } else if (!isLoggedIn) {
      // If user token is missing or invalid (expired), go to registration/login screen
      navigate(offeringUrl("registration"));
    } else if (!investmentId) {
      // If no investment id, investor can choose an investment
      navigate(offeringUrl("choose"));
    } else if (!startUri) {
      // If there is an investment, but the workflow step isn't loaded, go to base investment URL until load is complete
      navigate(offeringUrl(investmentId));
    } else {
      // Everything is loaded for investment, so go to correct workflow step
      navigate(offeringUrl(investmentId, startUri));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user,
    slug,
    startUri,
    offering,
    investmentId,
    isLoading,
    investment?.next,
    complete,
    navigate,
  ]);

  const [position, setPosition] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [position, location]);

  if (state === "loading" || delay || !offering) {
    return <LoadingView isError={isError} />;
  }

  if (investmentId && (!investment || isLoading)) {
    return <LoadingView isError={isError} />;
  }

  let content = <Outlet />;

  return (
    <Stack
      direction={{ base: "column", lg: "row" }}
      as="main"
      minH="100vh"
      spacing={0}
      bgColor="background"
    >
      <WorkflowContext.Provider
        value={{
          isLoggedIn,
          position,
          setPosition,
          user,
          setUser,
          offering,
          investment,
          analytics,
          refetchInvestment,
        }}
      >
        <NavBar offering={offering} position={position}>
          {content}
        </NavBar>
      </WorkflowContext.Provider>
    </Stack>
  );
};

export default Workflow;

import
  {
    Alert,
    AlertIcon,
    FormControl,
    FormLabel,
    useToast,
  } from "@chakra-ui/react";
import { useFormik } from "formik";
import React, { useContext, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useWorkflowPosition from "../../../hooks/useWorkflowPosition";

import CommonStockForm from "@equidefi/portals/components/investments/CommonStockForm";
import { offeringIsTypeRegA } from "@equidefi/shared";
import { Heading, Input, Text } from "@equidefi/ui";
import RadioGroup from "@equidefi/ui/forms/RadioGroup";
import NumberFormat from "react-number-format";
import ConvertibleForm from "../../../components/subscription/ConvertibleForm";
import DocumentReview from "../../../components/subscription/DocumentReview";
import { isConvertibleOffering } from "../../../helpers";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import { useUpdateInvestmentSubscription } from "../../../hooks/useInvestments";
import { WorkflowContext } from "../context";
import { WorkflowContainer } from "../WorkflowContainer";
import WorkflowForm from "../WorkflowForm";
import { subscriptionSchema } from "./schema";
import SubscriptionError from "./SubscriptionError";

const ACCREDITAION_OPTIONS = [
  { label: "Yes", value: "true" },
  { label: "No", value: "false" },
];

export const WorkflowSubscription = () => {
  const { offering, investment, refetchInvestment } =
    useContext(WorkflowContext);
  useWorkflowPosition(2);
  const toast = useToast();

  const [spinner, showSpinner] = useState(false);
  useDocumentTitle(["Subscription", offering?.name, investment?.issuer?.name]);
  const updateInvestmentSubscription = useUpdateInvestmentSubscription(
    investment?.id
  );
  const navigate = useNavigate();

  const unitPrice = Number(offering?.unit_price?.replace(/[^0-9.-]+/g, ""));
  const isOfferingRegA = offeringIsTypeRegA(offering);
  const validationSchema = useMemo(
    () => subscriptionSchema({ offering, investment, isOfferingRegA }),
    [offering, investment, isOfferingRegA]
  );

  const initialValues = {
    accredited: null,
    annual_income: null,
    quantity: investment?.quantity || 0,
    amount: investment?.quantity * unitPrice || 0,
  };

  const submitForm = async ({
    amount,
    quantity,
    accredited,
    annual_income,
  }) => {
    showSpinner(true);

    try {
      await updateInvestmentSubscription.mutateAsync({
        accredited: accredited === "true",
        annual_income,
        quantity,
        amount,
      });

      await refetchInvestment();

      navigate(`/offerings/${offering.slug}/${investment?.id}/payment`);
    } catch (err) {
      toast({
        description: "Sorry! Something went wrong updating your subscription",
        isClosable: true,
        status: "error",
      });
      console.error(err);
    } finally {
      showSpinner(false);
    }
  };

  let InvestmentForm = CommonStockForm;
  if (isConvertibleOffering(offering.stock_type)) {
    InvestmentForm = ConvertibleForm;
  }

  const {
    handleSubmit,
    setFieldValue,
    setValues,
    handleBlur,
    validateForm,
    values,
    errors,
    isValid,
    touched,
  } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: submitForm,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
  });

  const isAccredited = values?.accredited === "true";

  if (!investment) return null;

  if (!["PAYMENT", "SUBSCRIPTION"].includes(investment.next)) {
    return <SubscriptionError />;
  }

  return (
    <WorkflowContainer
      title="Investment Amount"
      onContinue={handleSubmit}
      buttonProps={{ isDisabled: !isValid, isLoading: spinner }}
    >
      <WorkflowForm onSubmit={handleSubmit}>
        <FormControl mb={2}>
          <FormLabel>
            <Text mb="0">Are you an accredited investor?</Text>
          </FormLabel>

          <RadioGroup
            fieldName="accredited"
            options={ACCREDITAION_OPTIONS}
            defaultValue={values?.accredited}
            setFieldValue={setFieldValue}
          />
        </FormControl>
        <Text textStyle="context">
          Please see "
          <Text
            textStyle="context"
            as={Link}
            textDecor="underline"
            _hover={{ color: "blue.700" }}
            color="blue.600"
            to="https://www.sec.gov/education/capitalraising/building-blocks/accredited-investor"
            rel="noopener noreferrer"
            target="_blank"
          >
            what is an accredited investor
          </Text>
          " to make sure you make the right selection.
        </Text>

        {!isAccredited &&
          values.accredited &&
          (isOfferingRegA ? (
            <Input
              label="Annual Income"
              isInvalid={errors.annual_income && touched.annual_income}
              maxW="50%"
              as={NumberFormat}
              allowNegative={false}
              allowLeadingZeros
              thousandSeparator
              fixedDecimalScale={0}
              decimalScale={0}
              prefix="$"
              name="annual_income"
              aria-label="Annual income"
              value={values.annual_income}
              onValueChange={(...args) => {
                const [{ value }] = args;
                setFieldValue("annual_income", value);
                validateForm();
              }}
              helperText="If you are not accredited, you must provide your annual income."
              error={errors.annual_income}
            />
          ) : (
            <Alert
              status="warning"
              borderRadius="lg"
              border="1px solid"
              borderColor="equidefi.yellow"
              boxShadow="sm"
              mb={4}
            >
              <AlertIcon />
              <Text textStyle="body2" m={0}>
                You must be an accredited investor to invest in this offering
              </Text>
            </Alert>
          ))}

        {/* InvestmentForm must be either CommonStockForm or ConvertibleForm */}
        {(isAccredited || isOfferingRegA) && (
          <>
            <Heading textStyle="h1" fontWeight="bold" mb={0}>
              Amount
            </Heading>
            <Text>Select the amount you want to invest</Text>
            <InvestmentForm
              values={values}
              handleBlur={handleBlur}
              setValues={setValues}
              errors={errors}
              touched={touched}
              offering={offering}
            />
          </>
        )}

        <DocumentReview documents={offering.documents} />
      </WorkflowForm>
    </WorkflowContainer>
  );
};

import { useContext } from "react";

import { offeringIsActive } from "@equidefi/shared";
import UserToken from "@equidefi/portals/clients/UserToken";
import { useAuth } from "@equidefi/portals/components/AuthProvider";

import OfferingError from "../../../components/offerings/OfferingError";
import useWorkflowPosition from "../../../hooks/useWorkflowPosition";
import { WorkflowContext } from "../context";
import Register from "./Register";
import ExistInvestorRegister from "./ExistInvestorRegister";

const WorkflowRegistration = (props) => {
  const { offering } = useContext(WorkflowContext);
  const { isLoggedIn } = useAuth();

  useWorkflowPosition(1);

  if (offering?.status && !offeringIsActive(offering)) {
    return (
      <OfferingError
        title="Offering Not Available"
        message={`Sorry but you cannot invest in this offering, yet.`}
      />
    );
  }

  return isLoggedIn ? 
    <ExistInvestorRegister offering={offering} /> : 
    <Register offering={offering} />;
};

export default WorkflowRegistration;

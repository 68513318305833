import { useCookies } from "react-cookie";
import React from "react";
import { Text } from "@equidefi/ui";
import { useReinvestButton } from "../../../components/investment/ReinvestButton";
import { WorkflowContainer } from "../WorkflowContainer";
import { isRegD506COffering } from "../../../helpers";
import RegDOfferingAlert from "./RegDOfferingAlert";
import "./auth.css";


const ExistInvestorRegister = ({ offering }) => {
  const [cookies] = useCookies(["bcode"]);
  const { onReinvest, isLoading } = useReinvestButton(offering?.slug);
  const handleReinvest = () => onReinvest(offering.id, cookies.bcode);

  return (
    <WorkflowContainer
      title="Welcome, Investor!"
      buttonProps={{ isLoading }}
      onContinue={handleReinvest}
      buttonLabel="Start Investment"
    >
      <Text>
        Thank you for your interest in {offering.issuer.name}
      </Text>

      {isRegD506COffering(offering.exemption_type) && <RegDOfferingAlert />}

      <Text textStyle="body2" mt={8} mb={12} align="center" fontWeight="bold">
        We see you are already logged in, click &quot;Start Investment&quot; to begin.
      </Text>
    </WorkflowContainer>
  );
};

export default ExistInvestorRegister;
